import React, { useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { cssMap } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { LinkItem } from '@atlaskit/side-navigation';
import Tooltip from '@atlaskit/tooltip';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';

import { SPACE_SETTINGS_LANDING_PAGE } from '@confluence/named-routes';
import { RoutesContext } from '@confluence/route-manager';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';

import {
	isForgeAppRoute,
	isSPASettingsRoute,
	isSpacePermissionsRoute,
	isSpaceMembersRoute,
} from '../helpers/webItem';

import { SettingsIcon } from './img/SettingsIcon';

const styles = cssMap({
	root: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			padding: `0px ${token('space.050')} 0px ${token('space.100')}`,
			height: '36px',
			minHeight: '36px',
			borderRadius: '3px',
			font: token('font.body'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			span: {
				columnGap: token('space.100'),
			},
			backgroundColor: 'transparent',
			'&:hover': {
				backgroundColor: token('color.background.neutral.subtle.hovered'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&, &:visited, &:active, &:focus': {
				color: token('color.text.subtle'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
			textDecoration: 'none !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'[data-item-elem-before=true]': {
				marginRight: '0px',
				height: '24px',
				width: '24px',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
				'img, span, svg': {
					height: '20px',
					width: '20px',
				},
			},
		},
	},
	isSelected: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			backgroundColor: token('color.background.selected'),
			'&:hover': {
				backgroundColor: token('color.background.selected.hovered'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&, &:visited, &:active, &:focus': {
				color: token('color.text.selected'),
			},
		},
	},
});

const i18n = defineMessages({
	generalSpaceSettingsLink: {
		id: 'space-settings.sidebar-links.space-settings.link',
		defaultMessage: 'Space settings',
		description: 'Space Settings Menu Item',
	},
	spaceSettingsTooltip: {
		id: 'space-settings.sidebar-links.space-settings.tooltip',
		defaultMessage: 'View settings for this space',
		description: 'Tooltip message on hover of space settings space link',
	},
});

const ANALYTICS_SOURCE = 'spaceSettingsV2SideNavLink';

export type SpaceSettingsSidebarLinksProps = {
	spaceKey?: string;
	isSpaceSettingsScreen: boolean;
	isSideNavRefreshAutomationEnabled?: boolean;
};

export const SpaceSettingsSidebarLinks = ({
	spaceKey,
	isSpaceSettingsScreen,
	isSideNavRefreshAutomationEnabled = false,
}: SpaceSettingsSidebarLinksProps) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { location, matchRoute } = useContext(RoutesContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const trackClickAnalytics = (clickedLinkId: string | null) =>
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'spaceSettingsSidebarLink',
				source: ANALYTICS_SOURCE,
				attributes: {
					linkId: clickedLinkId,
				},
			},
		}).fire();

	const isForgeAppPage = isForgeAppRoute(matchRoute, location);
	const isSPASettingsPage = isSPASettingsRoute({
		matchRoute,
		location,
		isSideNavRefreshAutomationEnabled,
		isSpaceDetailsSPAPageEnabled: fg('confluence-fe-space-settings-space-details'),
	});

	const isEitherSpacePermisisonsPage =
		isSpacePermissionsRoute(matchRoute, location) || isSpaceMembersRoute(matchRoute, location);
	const showSettingsItems =
		isSpaceSettingsScreen || isForgeAppPage || isSPASettingsPage || isEitherSpacePermisisonsPage;

	return (
		<>
			<Tooltip position="top" content={<FormattedMessage {...i18n.spaceSettingsTooltip} />}>
				{(tooltipProps) => (
					<SpotlightTarget name="live-pages-changeboarding.space-settings">
						<li>
							<LinkItem
								key="space-settings"
								iconBefore={<SettingsIcon isSelected={showSettingsItems} />}
								css={[styles.root, showSettingsItems && styles.isSelected]}
								// in the future this will be true when user is on the main
								// space settings overview page with all the card links
								isSelected={showSettingsItems}
								href={SPACE_SETTINGS_LANDING_PAGE.toUrl({ spaceKey })}
								{...tooltipProps}
								onClick={() => trackClickAnalytics('space-settings')}
								testId={`space-navigation-space-settings-v2${showSettingsItems ? '-selected' : ''}`}
								data-vc="space-settings-sidebar-link"
								{...ssrPlaceholderIdProp}
							>
								<FormattedMessage {...i18n.generalSpaceSettingsLink} />
							</LinkItem>
						</li>
					</SpotlightTarget>
				)}
			</Tooltip>
		</>
	);
};
